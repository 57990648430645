























































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import {
	daysInWeek,
	differenceInWeeks,
	getWeek,
	getYear,
	isToday,
	setWeek,
	startOfWeek,
} from "date-fns";
import { nl } from "date-fns/locale";
import type Highcharts from "highcharts";
import { DateTime } from "klokwerk";
import { mapStores } from "pinia";
import WidgetPreviousAppointments from "@/components/MemberDashboard/Widget/PreviousAppointments.vue";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachappActieVoorLid,
	IomodelsCoachappFitnessAfspraakVoorLid,
	IomodelsCoachappFrequentieVoorWeek,
	IomodelsCoachappLidInfo,
} from "@/lib/backend/club.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { useAuthStore } from "@/pinia/auth";
import { useCoachStore } from "@/pinia/coach";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useLocationStore } from "@/pinia/location";
import { dagMaand, dagMaandJaar, getWeekNumber, tijdstip } from "@/utils/date";

type Data = {
	loading: boolean;
	memberId: number | undefined;
	coachingsGroep?: IomodelsCoachappLidInfo["coachingsGroep"];
	coachLid?: IomodelsCoachappLidInfo["coachLid"];
	options: Highcharts.Options;
};

export default defineComponent({
	name: "PfgGebruikerDashboard",
	components: { WidgetPreviousAppointments },
	data(): Data {
		return {
			loading: false,
			memberId: undefined,
			coachingsGroep: undefined,
			coachLid: undefined,
			options: {
				chart: {
					marginTop: 60,
					marginRight: 200,
					type: "spline",
				},
				xAxis: {
					// type: 'datetime',
					title: {
						text: "Weken",
						align: "middle",
					},
					crosshair: false,
				},
				yAxis: {
					tickInterval: 1,
					min: 0,
					title: {
						text: "Bezoeken",
						align: "middle",
					},
					plotLines: [
						{
							color: "#7b7aa0",
							dashStyle: "shortdash",
							width: 2,
						},
					],
				},
				plotOptions: {
					series: {},
				},
				legend: {
					enabled: false,
				},
				series: [
					{
						name: "Aantal bezoeken",
						data: [],
					},
				],
			},
		};
	},
	computed: {
		...mapStores(useAuthStore, useLocationStore, useCoachStore, useGebruikerStore),
		afspraken(): IomodelsCoachappFitnessAfspraakVoorLid[] {
			return (
				this.gebruikerStore?.aankomendeAfspraken
					.filter(({ datum }) => new Date(datum).getTime() > new Date().getTime())
					.sort(
						({ datum: datumA }, { datum: datumB }) =>
							new Date(datumA).getTime() - new Date(datumB).getTime(),
					) ?? []
			);
		},
		visits(): Array<IomodelsCoachappFrequentieVoorWeek & { date: Date }> {
			const weken = this.gebruikerStore.frequentieGrafiek?.weken;

			if (!weken) return [];

			return weken
				.sort((a, b) => {
					if (a.week <= 10 && b.week >= 40) return 1;
					if (b.week <= 10 && a.week >= 40) return -1;

					return a.week - b.week;
				})
				.map((item, _, all) => {
					const arrayContainsPastYear = all.some(({ week }) => week >= 40);

					let date = new DateTime();

					if (arrayContainsPastYear && item.week <= 10) {
						date = date.setFullYear((current) => current.fullYear + 1);
					}

					return {
						week: item.week,
						frequentie: item.frequentie,
						date: startOfWeek(setWeek(date.native, item.week), {
							weekStartsOn: 1,
						}),
					};
				});
		},
		actions(): {
			visits: Array<{ completed: boolean; number: number }>;
			coachActions: Array<
				IomodelsCoachappActieVoorLid & {
					korteToelichting: string;
					icon: string;
					link?: unknown;
				}
			>;
		} {
			const currentWeekNumber = getWeekNumber();
			const currentVisits = this.gebruikerStore.frequentieGrafiek.weken?.find(
				({ week }) => week === currentWeekNumber,
			)?.frequentie;
			const goal = this.gebruikerStore.doelFrequentie?.doelfrequentie ?? 1;

			const amount = goal >= (currentVisits ?? 0) ? goal : currentVisits;

			const visits = Array(amount)
				.fill(undefined)
				.map((_, index) => ({
					completed: false,
					number: index + 1,
				}));

			if (currentVisits) {
				for (let i = 0; i < currentVisits; i++) {
					visits[i].completed = true;
				}
			}

			const coachActions = this.gebruikerStore.actions.map(({ actie, ...rest }) => {
				const korteToelichtingMap: Record<IomodelsCoachappActieVoorLid["actie"], string> = {
					"app-installeren": "App installeren",
					bellen: "Bellen",
					doel: "Doel opstellen",
					frequentie: "Sport te weinig",
					groepslessen: "Volgt te weinig",
					metingen: "Meting afnemen",
					schemas: "Schema opstellen",
					"persoonlijk-gegevens": "Gegevens voltooien",
					"coach-voor-lid": "Coach toevoegen",
					"onbekende actie": "Actie uitvoeren",
				};

				const iconMap: Record<IomodelsCoachappActieVoorLid["actie"], string> = {
					"app-installeren": "icon_app",
					bellen: "icon_app",
					doel: "icon_doel",
					frequentie: "icon_frequentie",
					groepslessen: "icon_groepslessen",
					metingen: "icon_metingen",
					schemas: "icon_schemas",
					"persoonlijk-gegevens": "icon_persoonlijk",
					"coach-voor-lid": "icon_coaches",
					"onbekende actie": "icon_actie",
				};

				const linkMapName: Record<IomodelsCoachappActieVoorLid["actie"], string> = {
					"app-installeren": "Gebruiker",
					bellen: "Gebruiker belpoging",
					doel: "Gebruiker doel",
					frequentie: "Gebruiker persoonlijk gegevens",
					groepslessen: "Gebruiker groepslessen",
					metingen: "Gebruiker metingen",
					schemas: "Gebruiker schema",
					"persoonlijk-gegevens": "Gebruiker persoonlijk gegevens",
					"coach-voor-lid": "Gebruiker persoonlijk gegevens",
					"onbekende actie": "Gebruiker",
				};

				return {
					...rest,
					actie,
					korteToelichting: korteToelichtingMap[actie],
					icon: iconMap[actie],
					link: {
						name: linkMapName[actie],
						params: { id: this.gebruikerStore.id },
					},
				};
			});

			return {
				visits,
				coachActions,
			};
		},
	},
	watch: {
		$route: "check",
		coachLid: "saveCoachLid",
		coachingsGroep: "saveCoachingsGroep",
		"gebruikerStore.lidInfo"(newValue: IomodelsCoachappLidInfo) {
			this.coachLid = newValue.coachLid;
			this.coachingsGroep = newValue.coachingsGroep;
		},
	},
	async created() {
		await this.check();
	},
	async destroyed() {
		this.reset();
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			this.memberId = numberId;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.locationStore.init();
			await this.coachStore.init();

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getActions();
			await this.gebruikerStore.getDoelFrequentie();
			await this.gebruikerStore.getDoel();
			await this.gebruikerStore.getFrequentieGrafiek();
			await this.gebruikerStore.getDashboardLidInfo();
			await this.gebruikerStore.getSubscription();
			await this.gebruikerStore.getAankomendeAfspraken();

			this.updateGraph();
		},
		async saveCoachLid() {
			if (this.coachLid === this.gebruikerStore.lidInfo?.coachLid) return;

			this.$notify({ clean: true });

			if (this.coachLid) {
				await clubApi.lid.coachVoorLidWijzigen(this.gebruikerStore.id, this.coachLid);

				this.$notify({
					type: "success",
					title: "Coach aangepast",
					text: "De toegewezen coach van het lid is succesvol aangepast.",
				});
			} else {
				await clubApi.lid.coachVoorLidVerwijderen(this.gebruikerStore.id);

				this.$notify({
					type: "success",
					title: "Coach verwijderd",
					text: "De toegewezen coach van het lid is succesvol verwijderd.",
				});
			}

			await this.gebruikerStore.getDashboardLidInfo();
			await this.gebruikerStore.getActions();
		},
		async saveCoachingsGroep() {
			if (this.coachingsGroep === this.gebruikerStore.lidInfo?.coachingsGroep) return;

			this.$notify({ clean: true });

			if (this.coachingsGroep) {
				await clubApi.lid.groepVoorLidWijzigen(
					this.gebruikerStore.id,
					encodeURIComponent(this.coachingsGroep),
				);

				this.$notify({
					type: "success",
					title: "Doelgroep aangepast",
					text: "De type sporter van het lid is succesvol aangepast.",
				});
			}

			await this.gebruikerStore.getDashboardLidInfo();
		},
		async updateGraph() {
			if (!this.visits || this.visits.length === 0) return [];

			const actualStart = this.visits[0].date;

			// Set plotline
			if (this.gebruikerStore.doelFrequentie?.doelfrequentie) {
				const plotLine0 = (this.options.yAxis as Highcharts.AxisOptions).plotLines?.[0];

				if (plotLine0) {
					plotLine0.value = this.gebruikerStore.doelFrequentie.doelfrequentie;
				}

				(this.options.yAxis as Highcharts.AxisOptions).minRange =
					this.gebruikerStore.doelFrequentie.doelfrequentie;
				this.options.series?.push({
					color: "#7b7aa0",
					name: "Streef bezoeken",
					dashStyle: "shortdash",
				});
				(this.options.chart as Highcharts.ChartOptions).marginRight = 180;
			} else {
				(this.options.chart as Highcharts.ChartOptions).marginRight = 0;
			}

			const weeksBack = differenceInWeeks(new Date(), actualStart);

			// week (year) text used for x-axis
			const weeks: string[] = [];
			const columnData: [number, number][] = [];
			for (let index = weeksBack; index >= 0; index -= 1) {
				const dateTime = new DateTime().setDate((current) => current.date - daysInWeek * index);
				const week = getWeek(dateTime.native, {
					locale: nl,
					weekStartsOn: 1,
				});
				const data = this.visits.find((visit) => visit.week === week)?.frequentie ?? 0;

				weeks.push(`Week ${week} - ${getYear(dateTime.native)}`);
				columnData.push([weeksBack - index, data]);
			}

			(this.options.xAxis as Highcharts.AxisOptions).categories = weeks;

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.options.series![0].data = columnData;
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));
		},
		getAppointmentData(appointment: IomodelsCoachappFitnessAfspraakVoorLid) {
			return Object.entries(appointment)
				.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
				.filter(([, value]) => value === true)
				.map(([key]) => ({
					key,
					...APPOINTMENT_MAP[key],
				}));
		},
		dagMaand,
		tijdstip,
		dagMaandJaar,
		getWeekNumber,
		isToday,
	},
});
